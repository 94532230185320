import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import LocalizedLink from "components/localizedLink";
import useTranslations from "hooks/useTranslations";
import "react-loading-skeleton/dist/skeleton.css";
import { isNil } from "ramda";
import { Icon } from "leaflet";

const MapWithUniqueName = ({ incidents, viewport }) => {
  const isBrowser = typeof window !== "undefined";
  const tr = useTranslations();
  const mapState = {
    attribution: tr(
      "For security reasons, the location coordinates used for maps in this database and report are approximated and intentionally, slightly inaccurate."
    ),
    tile: "https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png",
  };

  if (isNil(viewport)) return <div />;
  const [content, setContent] = useState("");
  const [incident, setIncident] = useState("");
  const handleMouseover = (e, id, title) => {
    setIncident(id);
    setContent(
      <div>
        <div>{title}</div>
      </div>
    );
    e.target.openPopup();
  };
  const handleClick = (e, id, title, image) => {
    const imageUrl = `https://cube.syrianarchive.org/syria/${image}`;
    setIncident(id);
    setContent(
      <div>
        {image && <img src={imageUrl} alt={title} />}
        <div>{title}</div>
      </div>
    );
    e.target.openPopup();
  };

  return (
    <MapContainer
      center={[viewport?.lat, viewport?.lng]}
      zoom={viewport?.zoom}
      scrollWheelZoom={false}
      style={{
        width: "100%",
        height: "50vh",
        position: "relative",
        zIndex: "1",
      }}
    >
      <TileLayer attribution={mapState?.attribution} url={mapState?.tile} />
      {incidents?.map((node) => {
        if (!isNaN(Number(node?.latitude)) && !isNaN(Number(node?.longitude))) {
          return (
            <Marker
              key={node?.id}
              position={{
                lat: Number(node?.latitude),
                lng: Number(node?.longitude),
              }}
              icon={
                isBrowser
                  ? new Icon({
                      iconUrl:
                        "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
                      shadowUrl:
                        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                      iconSize: [20, 35],
                      iconAnchor: [12, 41],
                      popupAnchor: [1, -34],
                      shadowSize: [41, 41],
                    })
                  : null
              }
              riseOnHover={true}
              opacity={0.8}
              eventHandlers={{
                mouseover: (e) => {
                  handleMouseover(e, node?.id, node?.title);
                },

                click: (e) => {
                  handleClick(e, node?.id, node?.title, node?.image);
                },
              }}
            >
              {incident && incident === node.id && content && (
                <Popup>
                  <LocalizedLink
                    to={`observation?id=${node?.id}`}
                    target="_blank"
                  >
                    <div style={{ minWidth: "300px" }}>{content}</div>
                  </LocalizedLink>
                </Popup>
              )}
            </Marker>
          );
        }
      })}
    </MapContainer>
  );
};

export default MapWithUniqueName;
